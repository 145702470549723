import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import React from "react";

const CardSkeleton = ({ cards = 1 }) => {

    return Array(cards)
        .fill(0)
        .map((item,i) => (
            <div className="box" key={i}>
                <div className="title">
                    <h3><Skeleton /></h3>
                </div>
                <div className="columns-2">
                    <div><Skeleton count={2} style={{ marginBottom: ".5em"}} /></div>
                    <div><Skeleton count={2} style={{ marginBottom: ".5em"}} /></div>
                </div>
            </div>
    ));
}
export default CardSkeleton