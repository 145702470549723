import * as helpers from '../../utils/helpers'
import React, { useEffect, useState } from 'react';

const SearchForm = ({target}) => {
    const [query,setQuery] = useState("");
    const [validationMessage,setValidationMessage] = useState("")
    
    useEffect(()=>{
        let fields = document.querySelectorAll(`input[data-input-type='${target}'`)
        if (target === "cnpj") fields.forEach(field=>field.setAttribute("inputmode","numeric"))
    }, [])

    let placeholder = ""
    switch (target) {
        case "cnpj":
            placeholder = "Digite o número do CNPJ para buscar"
            break;
        case "socio":
            placeholder = "Digite o nome da pessoa para buscar"
            break;
        case "cnae":
            placeholder = "Digite o nome ou código de uma atividade"
            break;
        case "cidade":
            placeholder = "Encontre uma cidade"
            break;
        default:
            break;
    }

    const constantlyChecking = e => {
        // Remove a máscara antes de aplicá-la novamente
        let value = e.target.value.replace(/\D/g, '');
        const validationDiv = e.target.parentNode.querySelector("div")

        // remove class
        e.target.className = ""
        validationDiv.className = "";
        
        // internal user
        if (value === "71999929292") {
            setQuery("");
            let myCookie = document.cookie;
            if (myCookie.split("; ").indexOf("internal_user=true") === -1) {
                document.cookie = `internal_user=true; path=/`;
                alert("Cookie não existe. Criado ✅");
            } else {
                alert("Cookie já existe.");
            }
        }
        else {
            switch (target) {
                case "cnpj":
                    let valid = helpers.validarCNPJ(value)
                    if (valid) {
                        e.target.classList.add("valid")
                        validationDiv.classList.add("active")
                        window.location.href = helpers.createUrl("cnpj",{"cnpj_completo":value})

                    } else if (value.length === 14) { 
                        e.target.classList.add("invalid");
                        validationDiv.classList.add("active")
                        setValidationMessage("CNPJ inválido")
                    }
                    // Aplica a máscara
                    setQuery(formatCnpj(value))
                    break;
                default:
                    // console.log("cnaeeeeeee")
                    if (e.target.value.length > 0 && e.target.value.length < 3) {
                        validationDiv.classList.add("active")
                        setValidationMessage("Digite ao menos 3 caracteres");
                    }
                    break;
                }

        }
    }

    function formatCnpj(cnpj) {
        // Remove qualquer caractere não numérico
        cnpj = cnpj.replace(/\D/g, '');

        // Aplica a máscara
        cnpj = cnpj.replace(/(\d{2})(\d)/, '$1.$2');
        cnpj = cnpj.replace(/(\d{3})(\d)/, '$1.$2');
        cnpj = cnpj.replace(/(\d{3})(\d)/, '$1/$2');
        cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, '$1-$2');

        return cnpj;
    }

    const handleSubmit = e => {
        e.preventDefault()
        switch (target){
            case "cnpj":
                let value = query.replace(/\D/g, '');
                if (!helpers.validarCNPJ(value)) console.log("não pode submeter");
                break;
            default:
                console.log("submeteu")
                break;
        }
    }
    return (
        <form onSubmit={handleSubmit}>
            <input type="search" data-input-type={target} maxLength="18" placeholder={placeholder} required value={query} onChange={(e)=> {setQuery(e.target.value);constantlyChecking(e);}} />
            <div className="">{validationMessage}</div>
        </form>
    )
}
export default SearchForm