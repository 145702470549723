import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Footer from "./components/Footer"
import Home from "./components/Home"
import Static from "./components/Static";
import ConsultaCnpj from "./components/ConsultaCnpj"
import ConsultaSocio from "./components/ConsultaSocio"
import Socio from "./components/Socio"
import Cnpj from "./components/Cnpj"
import Error from "./components/Error";
import Reports from "./components/Reports";
import Report from "./components/Report";
import ScrollToTop from "./components/ScrollToTop"
import ConsultaCnae from "./components/ConsultaCnae"
import Cnae from "./components/Cnae"
import CnaeLocal from "./components/CnaeLocal"


function App() {
  // console.log("ambiente da aplicacao:",process.env.NODE_ENV)
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/politicas-de-privacidade" element={<Static page="terms" />} />
        <Route path="/sobre-o-site" element={<Static page="about" />} />
        <Route path="/consulta-cnpj" element={<ConsultaCnpj />} />
        <Route path="/consulta-socio/:id" element={<Socio />} />
        <Route path="/consulta-socio" element={<ConsultaSocio />} />
        <Route path="/consulta-cnae" element={<ConsultaCnae />} />
        <Route path="/consulta-cnae/:id" element={<Cnae />} />
        <Route path="/consulta-cnae/:id/:local" element={<CnaeLocal />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/reports/:id" element={<Report />} />
        <Route path="/" element={<Home />} />
        <Route path="/:id" element={<Cnpj />} />
        <Route path="*" element={<Error statusCode={404} />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
