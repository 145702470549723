import Header from "../Header"
import Breadcrumbs from "../Breadcrumbs"
import React, { useEffect } from 'react';
import { metaData } from "../../utils/helpers";
import Ads from "../Ads";
import * as helpers from "../../utils/helpers"

const Static = ({page}) => {
    let content = metaData(page);
    useEffect(() => {
        document.body.classList.add('static');
        helpers.applyMetaData(content)
        return () => {
          document.body.classList.remove('static');
          helpers.removeMetaData()
        };
      }, [content]); // O array vazio significa que este useEffect será executado apenas uma vez (semelhante a componentDidMount e componentWillUnmount)

      let myDataLayer = {
            "content_group": "static", 
            "content_level": "top",
            "environment": process.env.NODE_ENV
        }

        helpers.applyDataLayer(myDataLayer)
    return (
        <>
            <Header />
            <div>
                <Ads />
                <Breadcrumbs path={content.breadcrumbs} />
                <section className="container bordered" id="intro">
                    <h1>{content.h1}</h1>
                </section>
            </div>
            <section className="container bordered">
                <main className="list">
                    <article dangerouslySetInnerHTML={{ __html: content.body }}></article>
                </main>
                
            </section>
        </>
    )
}
export default Static;