import Header from "../Header"
import React, { useEffect } from 'react';
import * as helpers from "../../utils/helpers"

const Error = () => {
    document.title="Página não encontrada - InfoCNPJ.com";
    useEffect(() => {
        document.body.classList.add('error');
        // window.dataLayer.push({"content_group": "error"});
        return () => {
          document.body.classList.remove('error');
        };
      }, []);

      let myDataLayer = {
            "content_group": "error", 
            "content_level": "single",
            "environment": process.env.NODE_ENV
        }

        helpers.applyDataLayer(myDataLayer)

    return (
        <>
            <Header />
            <div>
                <section className="container bordered" id="intro">
                    <h1>Página não encontrada</h1>
                </section>
            </div>
            <section className="container bordered">
                <main className="list">
                    <article>A página que você tentou acessar não foi encontrada.</article>
                </main>
            </section>
        </>
    )
}
export default Error;