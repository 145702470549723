import React from "react";
import { NavLink } from 'react-router-dom';
import Skeleton from "react-loading-skeleton";

const Breadcrumbs = ({path,loaded}) => {
    if (!path && !loaded) return <div className="container bordered" style={{ marginBottom: "0.5em" }}><Skeleton /></div>;
    const entries = Object.entries(path);
    return (
        <nav id="breadcrumbs" className="container bordered">
            <ul>
                <li><NavLink to="/" data-link="breadcrumbs-1">CNPJ</NavLink></li>
                {
                    entries.map(([key, value], index) => (
                        <li key={key}>
                            {index === entries.length - 1 ? key : <NavLink to={value} data-link={`breadcrumbs-${index+2}`}>{key}</NavLink>}
                        </li>
                    ))
                }
            </ul>
        </nav>
    )
}
export default Breadcrumbs;