import React from "react"
import { NavLink } from "react-router-dom"
import * as helpers from "../../utils/helpers";

const CnpjItem = ({cnpj,empresa,resumido}) => {
    // console.log("cnpj",cnpj)
    let content = helpers.metaData("cnpj",{0:cnpj});
    let finalUrl = content.url;
    if (content.url.split("-").length === 1) { finalUrl = helpers.createUrl("cnpj",{"cnpj_completo":cnpj.cnpj_completo,"razao_social":cnpj.empresa.razao_social}) }
    let nome = empresa ? helpers.titleize(cnpj.empresa.razao_social) : (content.h1 ? content.h1 : helpers.titleize(cnpj.empresa.razao_social))
    return (
        <div className={resumido ? "box resumido" : "box"}>
            <div className="title">
                <div className="d-flex">
                    <h3><NavLink to={finalUrl} data-link="item-cnpj">{nome}</NavLink></h3>
                    <span className={cnpj.situacao_cadastral === "02" ? "active" : "inactive" }>{helpers.situacaoCadastral(cnpj.situacao_cadastral)}</span>
                </div>
                <p>CNPJ {helpers.formatarCNPJ(cnpj.cnpj_completo)}</p>
            </div>
            <div className="columns-2">
                <div>
                    <h4>Endereço</h4>
                    <p>{helpers.titleize(cnpj.endereco_logradouro_tipo)} {helpers.titleize(cnpj.endereco_logradouro)}{cnpj.endereco_numero ? ", " + cnpj.endereco_numero + ". " : null}{helpers.titleize(cnpj.endereco_bairro)}<br/>
                    {cnpj.endereco_codigo_municipio &&
                        cnpj.endereco_codigo_municipio.nome_ibge + "/" + cnpj.endereco_codigo_municipio.uf
                    }</p>
                </div>
                {!resumido &&
                <div>
                    <h4>Atividade principal</h4>
                    <p>{cnpj.cnae_principal.codigo} - {cnpj.cnae_principal.descricao}</p>
                </div>
                }
            </div>
        </div>
    )
}
export default CnpjItem