import React from "react"
import { useMatch, Link } from 'react-router-dom';

function ListItemLink({ to, exact, children, dataLink }) {
  const match = useMatch({ path: to, start: !exact }); // Use 'start' para coincidência de início

  return (
    <li className={match ? 'active' : ''}>
      <Link to={to} data-link={dataLink}>{children}</Link>
    </li>
  );
}

export default ListItemLink;