import React from "react"
import { NavLink } from "react-router-dom"
import * as helpers from "../../utils/helpers";

const CnaeItem = ({cnae,empresa}) => {
    // console.log("cnae",cnae)
    let finalUrl = helpers.createUrl("cnae",cnae);

    return (
        <div className="box">
            <div className="title">
                <div className="d-flex">
                    <h3><NavLink to={finalUrl} data-link="item-cnae">{cnae.descricao}</NavLink></h3>
                </div>
                <p>Código: {cnae.codigo}</p>
            </div>
            <div className="columns-2">
                <div>
                    <h4>Seção</h4>
                    <p>{cnae.secao}</p>
                </div>
                <div>
                    <h4>Divisão</h4>
                    <p>{cnae.divisao}</p>
                </div>
            </div>
        </div>
    )
}
export default CnaeItem