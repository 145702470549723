import Header from "../Header"
import Breadcrumbs from "../Breadcrumbs"
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchSocio, removeSocios } from "../../store/socios"
import { useParams } from 'react-router-dom';
import * as helpers from "../../utils/helpers";
import CnpjItem from "../CnpjItem";
import Ads from "../Ads";
import CardSkeleton from "../CardSkeleton";
import Skeleton from "react-loading-skeleton";

const Socio = () => {
    const {id} = useParams();
    const idArray = id.split("-")
    const hash = idArray[idArray.length-1];

    const socios = useSelector(state => state.socios ? Object.values(state.socios) : []);
  
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    
    let content = {}

    useEffect(() => {
      document.body.classList.add('socio');
      return () => {
        document.body.classList.remove('socio');
        helpers.removeMetaData()
      };
    }, []);

    const socio = socios.find(socio => socio.hash_socio === hash);

    useEffect(() => { 
      dispatch(fetchSocio(hash))
        .then(()=>setLoaded(true))
      return () => dispatch(removeSocios());
      }, [dispatch,id,hash])

      let estabelecimentos = [];
      // console.log("socios no componente Socio",socios)
      // console.log("socio",socio)
      if (loaded && socios[1]) {
        estabelecimentos = Object.values(socios[1]);
      
        if (estabelecimentos) {
          // Cria um conjunto para rastrear cnpj_completo únicos
          const cnpjSet = new Set();
          
          // Filtra os elementos distintos com base em cnpj_completo
          estabelecimentos = estabelecimentos.filter(estabelecimento => {
            if (!cnpjSet.has(estabelecimento.cnpj_completo)) {
              // Adiciona o cnpj_completo ao conjunto
              cnpjSet.add(estabelecimento.cnpj_completo);
              return true; // Mantém o elemento na lista filtrada
            }
            return false; // Descarta elementos duplicados
          });
        
          // Ordena por situacao_cadastral e data_inicio_atividade
          estabelecimentos.sort((a, b) => {
            const situacaoComparison = a.situacao_cadastral.localeCompare(b.situacao_cadastral);
            // Se houver empate na primeira ordenação, use a segunda ordenação
            return situacaoComparison !== 0 ?
              situacaoComparison :
              a.data_inicio_atividade.localeCompare(b.data_inicio_atividade);
          });
        }
      }

      if (loaded) {
      
        content = helpers.metaData("socio",socio);
        // if (content.url !== window.location.pathname) window.location.replace(content.url);
        helpers.applyMetaData(content)
        let myDataLayer = {
            "content_group": "socio", 
            "content_level": "single",
            "environment": process.env.NODE_ENV
        }

        helpers.applyDataLayer(myDataLayer)

      }

      return (
          <>
            <Header />
            <Breadcrumbs path={content.breadcrumbs} loaded={loaded} />
            <article className="container bordered" id="intro">
                <h1>{content.h1 || <Skeleton />}</h1>
                {!loaded && <div className="container"><Skeleton /></div> }
                <p dangerouslySetInnerHTML={{ __html: content.body }}></p>
            </article>
            <section className="container bordered">
                <div className="columns-1fr_300px">
                  <main className="list">
                    <div className="d-flex">
                      <h2>{content.h1 ? `Empresas de  ${content.h1}` : <Skeleton />}</h2>
                      {estabelecimentos.length > 0 &&
                          <button data-action="dataRemoval" data-cnpj={estabelecimentos[0].cnpj_completo} onClick={helpers.handleDataRemovalClick}>Solicitação de remoção de dados</button>
                      }
                    </div>

                    {!loaded && <CardSkeleton cards={3} /> }
                    {estabelecimentos.length > 0 &&
                      estabelecimentos.sort((a, b) => a.situacao_cadastral.localeCompare(b.situacao_cadastral)).map(estabelecimento => {
                        return <CnpjItem cnpj={estabelecimento} key={estabelecimento.id} empresa={true} />
                      })
                    }
                  </main>
                  <aside>
                    <Ads placement={`single_socio-sidebar`} />
                  </aside>
                </div>
            </section>
          </>
      )
}
export default Socio;