const RECEIVE_CNPJ = 'cnpjs/receiveCnpj';
const RELATED_CNPJ = 'cnpjs/relatedCnpj';
const RECEIVE_CNPJS = 'cnpjs/receiveCnpjs';
const REMOVE_CNPJS = 'cnpjs/removeCnpjs';

const receiveCnpj = (estabelecimento) => {
    return {
      type: RECEIVE_CNPJ,
      payload: estabelecimento
    };
  };

const relatedCnpj = (related) => {
  return {
    type: RELATED_CNPJ,
    payload: related
  };
};

const receiveCnpjs = (estabelecimentos) => {
  return {
    type: RECEIVE_CNPJS,
    payload: estabelecimentos
  };
};

export const removeCnpjs = () => {
  return {
    type: REMOVE_CNPJS
  }
}

export const fetchCnpjs = (page = 1) => async dispatch => {
  let baseUrl = "/api/estabelecimentos"
  page ? baseUrl += `?pagina=${page}` : baseUrl += ""
  const res = await fetch(baseUrl)

  if (res.ok) {
    const cnpjs = await res.json();
    dispatch(receiveCnpjs(cnpjs));
  }
}

export const homeCnpjs = (count = 5) => async dispatch => {
  let baseUrl = `/api/estabelecimentos/home?count=${count}`
  const res = await fetch(baseUrl)

  if (res.ok) {
    const cnpjs = await res.json();
    dispatch(receiveCnpjs(cnpjs));
  }
}

export const fetchCnpj = (cnpjId) => async dispatch => {
  try {
    const res = await fetch(`/api/estabelecimentos/${cnpjId}`); // Chamada de API direta sem CSRF
    if (res.ok) {
      const cnpj = await res.json();
      dispatch(receiveCnpj(cnpj));
    } else {
      // Lidar com erros, se necessário
      console.error('Erro na chamada de API:', res.status, res.statusText);
    }
  } catch (error) {
    // Lidar com erros de rede, se necessário
    console.error('Erro de rede:', error);
  }
}

export const fetchRelatedCnpj = (cnpjId,cnae_principal,endereco_codigo_municipio) => async dispatch => {
  try {
    const res = await fetch(`/api/estabelecimentos/${cnpjId}?cnae_principal=${cnae_principal}&endereco_codigo_municipio=${endereco_codigo_municipio}`); // Chamada de API direta sem CSRF
    if (res.ok) {
      const cnpjs = await res.json();
      dispatch(relatedCnpj(cnpjs));
    } else {
      // Lidar com erros, se necessário
      console.error('Erro na chamada de API:', res.status, res.statusText);
    }
  } catch (error) {
    // Lidar com erros de rede, se necessário
    console.error('Erro de rede:', error);
  }
}
  

const cnpjsReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_CNPJ:
      return { ...state, [action.payload.estabelecimento.cnpj_completo]: action.payload.estabelecimento, socios: action.payload.socios, empresa: action.payload.empresa, unidades: action.payload.unidades, related: action.payload.related };
    case RECEIVE_CNPJS:
      return { ...action.payload };
    case REMOVE_CNPJS:
      return {};
    default:
      return state;
  }
};

export default cnpjsReducer;