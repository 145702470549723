import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const CookieAlert = ({iphone}) => {

  useEffect(() => {
    const myCookie = document.cookie;
    const cookieAlert = document.querySelector('.cookieAlert');

    if (cookieAlert && myCookie.split("; ").indexOf("cookie_consent=true") === -1) {
      cookieAlert.style.display = "block";
      document.getElementsByTagName("body")[0].classList.add("paddingBottom");
    } else if (cookieAlert) {
      document.getElementsByTagName("body")[0].classList.remove("paddingBottom");
      cookieAlert.remove();
    }

    const btnCloseCookieAlert = document.querySelector('.cookieAlert button');

    function closeCookieAlert() {
      window.dataLayer.push({
        "event": "agreed_to_be_tracked",
        "action":"cookie_consent"
      });
      document.cookie = `cookie_consent=true; path=/`;
      document.getElementsByTagName("body")[0].classList.remove("paddingBottom");
      cookieAlert.remove();
    }

    if (btnCloseCookieAlert) btnCloseCookieAlert.addEventListener("click", closeCookieAlert);
  }, []);

    return (
        <div className={iphone ? "cookieAlert iphone" : "cookieAlert"} role="alert">
            <p><span role="img" aria-label="cookie" aria-labelledby="cookieAlert">🍪</span> Utilizamos cookies para criar uma melhor experiência de navegação. Ao continuar navegando, você aceita nossas políticas de <NavLink to="/politicas-de-privacidade" rel="nofollow noopener" data-link="cookie-terms">Privacidade & Termos</NavLink>.</p>
            <button type="button" className="primary" aria-label="Concordo">Concordo <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" color="currentColor" fill="currentColor"/></svg></button>
        </div>
    )
}
export default CookieAlert;