import { NavLink } from 'react-router-dom';
import { createUrl } from '../../utils/helpers'
import React, { useEffect, useState } from "react";
import CookieAlert from "../CookieAlert";
const Footer = () => {
    const [iphone,setIphone] = useState(false);
    useEffect(()=>{
        let isIphone = /iPhone/i.test(navigator.userAgent);
        if (isIphone) setIphone(true);
    },[])
    return (
        <>
            <footer className={iphone ? 'iphone' : ''}>
                <div className="container bordered">
                    <div className="columns-footer">
                        <ul>
                            <li className="h4">Pesquisa</li>
                            <li><NavLink to={createUrl("consulta-cnpj")} data-link="footer">Consulta CNPJ</NavLink></li>
                            <li><NavLink to={createUrl("consulta-socio")} data-link="footer">Consulta Sócio</NavLink></li>
                            <li><NavLink to={createUrl("cnae")} data-link="footer">Consulta CNAE</NavLink></li>
                        </ul>
                        <ul>
                            <li className="h4">Sobre o site</li>
                            <li>O site InfoCNPJ.com foi criado para distribuir gratuitamente informações sobre todas as empresas brasileiras e os seus sócios. Leia mais <NavLink to={createUrl("about")} data-link="footer">sobre o site</NavLink>.</li>
                        </ul>
                    </div>
                </div>
                <div className="container">
                    <ul>
                        <li><NavLink to={createUrl("home")} data-link="footer">infocnpj.com</NavLink></li>
                    </ul>
                    <ul>

                        {(process.env.NODE_ENV === "development") &&
                        <li><NavLink to="/reports">Reports</NavLink></li>
                        }
                        <li><a href="https://cursodeexcel.com.br?utm_source=infocnpj&utm_medium=footer&utm_campaign=excel" rel="noopener external sponsored" data-link="footer">Curso de Excel</a></li>
                        <li><NavLink to={createUrl("about")} data-link="footer">Sobre o site</NavLink></li>
                        <li><NavLink to={createUrl("terms")} data-link="footer">Políticas de Privacidade</NavLink></li>
                    </ul>
                </div>
            </footer>
            <CookieAlert iphone={iphone} />
        </>
    )
}
export default Footer;