import React from "react"
import { NavLink } from "react-router-dom";
import { metaData, titleize, faixaEtaria } from "../../utils/helpers";

const SocioItem = ({socio}) => {
    // console.log("sócio recebido:",socio)
    let content = metaData("socio",socio);
    return (
        <div className="box">
            <div className="title">
                <div className="d-flex">
                    <h3><NavLink to={content.url} data-link="item-socio">{titleize(socio.nome)}</NavLink></h3>
                </div>
            </div>
            <div className="columns-2">
                <div>
                    <h4>CPF/CNPJ</h4>
                    <p>{socio.cpf_cnpj}</p>
                </div>
                <div>
                    <h4>Faixa etária</h4>
                    <p>{faixaEtaria(socio.faixa_etaria)}</p>
                </div>
            </div>
        </div>
    );
}
export default SocioItem