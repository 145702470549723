import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCnae, removeCnaes } from "../../store/cnaes"
import Header from "../Header"
import Breadcrumbs from "../Breadcrumbs"
import { NavLink, useParams } from "react-router-dom";
import * as helpers from "../../utils/helpers";
import Skeleton from "react-loading-skeleton";
import Error from "../Error";
import CardSkeleton from "../CardSkeleton"

const Cnae = () => {
    const {id} = useParams();
    const codigoCnae = id.split("-")[0]

    const cnaes = useSelector(state => state.cnaes ? state.cnaes : []);
    const dispatch = useDispatch();

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        document.body.classList.add("listing");
        return () => {
            document.body.classList.remove("listing");
            helpers.removeMetaData()
        };
    }, []);
    useEffect(() => {
      dispatch(fetchCnae(codigoCnae))
        .then(()=>setLoaded(true))
      return () => {
            dispatch(removeCnaes())
            setLoaded(false)
        };
      }, [dispatch,id,codigoCnae])


    // console.log("whaaaat",cnaes)
    if (loaded && !cnaes) return <Error />;

    let content
    let cnae

    if (loaded) {
        content = helpers.metaData("cnae",cnaes);
        // console.log("content",content)
        helpers.applyMetaData(content)
        // helpers.applyDataLayer(myDataLayer)
        // console.log("cnaes",cnaes)
        cnae = Object.values(cnaes)[0]

        // console.log("cnaes.cidades",cnaes.cidades)
        // cnaes.cidades.map(estado =>
        //     Object.values(estado).map(cidades=> 
        //         Object.values(cidades).map(cidade=>
        //                 console.log("cidade",cidade)
        //             )
        //     )
        // )
    }
    return (
        <>
            <Header />
            <div>
                <Breadcrumbs path={content?.breadcrumbs} loaded={loaded} />
                <section className="container bordered" id="intro">
                    <h1>{ loaded ? content.h1 : <Skeleton /> }</h1>
                    <h2>CNAE {codigoCnae}</h2>
                    <p dangerouslySetInnerHTML={{ __html: content?.metaDescription }}></p>
                </section>
            </div>
            <section className="container bordered">
                <div className="columns-330px_1fr">
                    <aside>
                        <div className="box">
                            <div className="title">
                                <h3>Detalhes do CNAE {codigoCnae}</h3>
                            </div>
                            <h4>Seção</h4>
                            <p>{ loaded ? cnae.secao : <Skeleton /> }</p>
                            <h4>Divisão</h4>
                            <p>{ loaded ? cnae.divisao : <Skeleton /> }</p>
                            <h4>Grupo</h4>
                            <p>{ loaded ? cnae.grupo : <Skeleton /> }</p>
                            <h4>Classe</h4>
                            <p>{ loaded ? cnae.classe : <Skeleton /> }</p>
                        </div>
                    </aside>
                    <main className="list">
                        <h2>Escolha uma cidade para ver empresas que exercem esta atividade</h2>
                        {!loaded && <CardSkeleton cards={27} /> }
                        {loaded && Object.entries(cnaes.cidades).map(([estado, cidades]) => {

                        return (
                            <div className="box" key={estado}>
                                <div className="title"><h3>{estado}</h3></div>
                                <h4>Capital</h4>
                                <ul>
                                {cidades.filter(cidade => helpers.isCapital(cidade.codigo_tom)).map(cidade => (
                                    <li key={cidade.codigo_tom}>
                                        <NavLink to={helpers.createUrl("cnae", cnae, cidade)} data-link="cnae-cidade">{cidade.nome_ibge}</NavLink>
                                    </li>
                                ))}
                                </ul>
                                { estado !== "Distrito Federal" && <h4>Interior</h4>}
                                <ul>
                                { estado !== "Distrito Federal" && cidades.map(cidade=> {
                                    if (helpers.isCapital(cidade.codigo_tom)) return;
                                    return <li key={cidade.codigo_tom}><NavLink to={helpers.createUrl("cnae",cnae,cidade)} data-link="cnae-cidade">{cidade.nome_ibge}</NavLink></li>
                                    }
                                )}
                                </ul>
                            </div>
                        )
                        })
                        }
                    </main>
                </div>
            </section>
        </>
    )
}

export default Cnae;