import Header from "../Header"
import Breadcrumbs from "../Breadcrumbs"
import React, { useEffect, useState } from "react";
import * as helpers from '../../utils/helpers'
import { useDispatch, useSelector } from "react-redux";
import { fetchSocios, removeSocios } from "../../store/socios"
import { useSearchParams } from 'react-router-dom';
import ConsultaSocioImg from '../../assets/consulta-socio.webp';
import SocioItem from "../SocioItem";
import Pagination from "../Pagination";
import Ads from "../Ads";
import CardSkeleton from "../CardSkeleton";

const ConsultaSocio = () => {
    const [searchParams] = useSearchParams();
    const pagina = searchParams.get("pagina")
    const [loaded,setLoaded] = useState(false);
    // console.log("pagina",pagina)

    const socios = useSelector(state => state.socios ? state.socios : []);

    const dispatch = useDispatch();

    useEffect(() => {
        document.body.classList.add('listing');
        return () => {
            document.body.classList.remove('listing');
            helpers.removeMetaData()
        };
    }, []); // O array vazio significa que este useEffect será executado apenas uma vez (semelhante a componentDidMount e componentWillUnmount)

    useEffect(() => { 
        dispatch(fetchSocios(pagina))
            .then(()=>setLoaded(true))
            .then(()=>window.scrollTo({
                top: 0,
                behavior: "smooth"
            }))
        return () => {
            dispatch(removeSocios())
            setLoaded(false)
        }
    }, [dispatch,pagina])

    let content = helpers.metaData("consulta-socio",{"ogImage":ConsultaSocioImg})
    let myDataLayer = {
        "content_group": "socio", 
        "content_level": "listing",
        "environment": process.env.NODE_ENV
    }
    helpers.applyMetaData(content)
    helpers.applyDataLayer(myDataLayer)

    return (
        <>
            <Header />
            <div>
                <Breadcrumbs path={content.breadcrumbs} />
                <section className="container bordered" id="intro">
                    <h1>Consulta Sócio</h1>
                    <h2>Encontre informações sobre sócios de empresas no Brasil</h2>
                </section>
            </div>
            <section className="container bordered">
                <div className="columns-330px_1fr">
                    <aside>
                        <div>
                            <Ads placement={`listing_socio-sidebar`} />
                        </div>
                    </aside>
                    <main className="list"> 
                        { !pagina &&
                        <article>
                            <p>Se você está em busca de informações sobre sócios de empresas, chegou ao lugar certo! A <strong>Consulta Sócio</strong> é a sua ferramenta abrangente para obter dados relevantes sobre indivíduos associados a empresas cadastradas na Receita Federal.</p>
                            <p>Descubra as empresas dos sócios, incluindo seus nomes e qualificação nas empresas. Nossa base de dados é frequentemente atualizada, garantindo que você tenha acesso às informações mais recentes.</p>
                            <h2>Como Utilizar a Consulta Sócio</h2>
                            <p><strong>Navegação pela Lista:</strong> Se você está explorando ou não possui um nome específico em mente, pode navegar pela lista completa de sócios. Basta rolar e explorar as informações disponíveis.</p>
                            <figure>
                                <div className="media">
                                    <span>Foto de <a href="https://unsplash.com/@cytonn_photography" rel="noopener nofollow external">Cytonn Photography</a> no <a href="https://unsplash.com/photos/two-people-shaking-hands-n95VMLxqM2I" rel="noopener nofollow external">Unsplash</a></span>
                                    <img loading="lazy" src={ConsultaSocioImg} title="Como fazer consulta de sócio na base da Receita Federal" alt="Consulta Sócio" width={1200} height={630} />
                                </div>
                                <figcaption>O Ministério da Fazenda divulga a lista de empresas brasileiras com os seus respectivos sócios</figcaption>
                            </figure>
                            <p>Nossa missão é fornecer informações confiáveis e transparentes, permitindo que você tome decisões informadas. Todos os dados apresentados são de fontes oficiais, garantindo a sua precisão.</p>
                            <Ads placement={`listing_socio-meio`} />
                            <h2>Por que Usar a Consulta Sócio?</h2>
                            <p>A Consulta Sócio é uma ferramenta valiosa para uma variedade de finalidades:</p>
                            <ul>
                                <li>Verificação de sócios em potenciais parcerias de negócios.</li>
                                <li>Pesquisa de antecedentes de indivíduos associados a empresas.</li>
                                <li>Identificação de relações comerciais e associações.</li>
                                <li>Tomada de decisões embasadas em informações concretas.</li>
                            </ul>
                            <p>Seja para fins comerciais ou pessoais, a <strong>Consulta Sócio</strong> é o seu guia confiável para obter insights sobre os sócios das empresas brasileiras.</p>
                            <p>Explore nossa ferramenta agora e tenha acesso a uma fonte rica de informações sobre sócios de empresas. Estamos aqui para fornecer as respostas de que você precisa.</p>
                        </article> }
                        <h2>Lista dos sócios cadastrados</h2>
                        {!loaded && <CardSkeleton cards={20} /> }
                        {socios.socios && Object.values(socios.socios).map(socio=> <SocioItem socio={socio} key={socio.id} /> ) }
                        {socios.pagination && <Pagination pagination={socios.pagination} type="socios" /> }
                    </main>
                </div>
            </section>
        </>
    )
}
export default ConsultaSocio;