const RECEIVE_SOCIO = 'socios/receiveSocio';
const RECEIVE_SOCIOS = 'socios/receiveSocios';
const REMOVE_SOCIOS = 'socios/removeSocios';

const receiveSocio = (socio) => {
    return {
      type: RECEIVE_SOCIO,
      payload: socio
    };
  };

const receiveSocios = (socios) => {
  return {
    type: RECEIVE_SOCIOS,
    socios
  };
};

export const removeSocios = () => {
  return {
    type: REMOVE_SOCIOS
  }
}

export const fetchSocios = (page = 1) => async dispatch => {
  let baseUrl = "/api/socios"
  page ? baseUrl += `?pagina=${page}` : baseUrl += ""
  const res = await fetch(baseUrl)
  
    if (res.ok) {
      const socios = await res.json();
      dispatch(receiveSocios(socios));
    }
  }

  export const homeSocios = (count = 6) => async dispatch => {
    let baseUrl = `/api/socios/home?count=${count}`
    const res = await fetch(baseUrl)
  
    if (res.ok) {
      const socios = await res.json();
      dispatch(receiveSocios(socios));
    }
  }
  
  export const fetchSocio = (socioId) => async dispatch => {
    try {
      const res = await fetch(`/api/socios/${socioId}`); // Chamada de API direta sem CSRF
      if (res.ok) {
        const socio = await res.json();
        dispatch(receiveSocio(socio));
      } else {
        // Lidar com erros, se necessário
        console.error('Erro na chamada de API:', res.status, res.statusText);
      }
    } catch (error) {
      // Lidar com erros de rede, se necessário
      console.error('Erro de rede:', error);
    }
  }
  

const sociosReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_SOCIO:
      return { ...state, [action.payload.socio.hash_socio]: action.payload.socio, estabelecimentos: action.payload.estabelecimentos };
    case RECEIVE_SOCIOS:
      return { ...action.socios };
    case REMOVE_SOCIOS:
      return {};
    default:
      return state;
  }
};

export default sociosReducer;