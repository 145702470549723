import React from "react"
// import banner from '../../assets/cursoexcel.webp';
import bannerSmaller from '../../assets/cursoexcel-smaller.webp';

// const isMobile = window.innerWidth <= 700;
// const bannerImg = isMobile ? bannerSmaller : banner

const Ads = ({placement}) => {
    let target = "https://cursodeexcel.com.br?utm_source=infocnpj&utm_medium=banner&utm_campaign=excel"
    if (placement) target += `&utm_content=${placement}`;
    // return null;
    return (
        <div className="ads container">
            <a href={target} rel="noopener external sponsored" data-link="ads"><img loading="lazy" src={bannerSmaller} alt="Banner Curso Microsoft Excel" title="Curso de Excel" height={540} width={540} /> </a>
        </div>
    )
}

export default Ads