import React from "react"
import { createUrl } from "../../utils/helpers";
import { NavLink } from 'react-router-dom';
const Pagination = ({pagination,type}) => {
    // console.log("pagination",pagination)
    // console.log("type",type)
    let printResults = {}
    switch (type) {
    case "cnpj":
        printResults = {
            "linkType": "consulta-cnpj",
            "label": "empresas"
        }
        break;
    case "socios":
        printResults = {
            "linkType": "consulta-socio",
            "label": "pessoas"
        }
        break;
    case "cnaes":
        printResults = {
            "linkType": "cnaeLocal",
            "label": "empresas"
        }
        break;
    case "cnaePage":
        printResults = {
            "linkType": "cnaePage",
            "label": "atividades"
        }
        break;
    default: 
        break;
    }
    // console.log("printResults",printResults)
    const current = pagination.current || 1
    const opcoes = { style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0, useGrouping: true };

    return (
        <div className="pagination">
            {pagination.total > 1 &&
            <>
                <nav role="navigation" aria-label="pager" className="pages">
                    <ul>
                        {pagination.previous && 
                        <li><NavLink to={createUrl(printResults.linkType,pagination.previous)} rel="prev" data-link="pagination-previous">← Anterior</NavLink></li>
                        }
                        {pagination.current > 3 && 
                        <li>...</li>
                        }
                        {pagination.previous > 1 && 
                            <li><NavLink to={createUrl(printResults.linkType,pagination.current-2)} rel="prev" data-link="pagination">{pagination.current-2}</NavLink></li>
                        }
                        {pagination.previous && 
                            <li><NavLink to={createUrl(printResults.linkType,pagination.current-1)} rel="prev" data-link="pagination">{pagination.current-1}</NavLink></li>
                        }
                        {pagination.current && 
                        <li className="current">{pagination.current}</li>
                        }
                        {pagination.next && 
                            <li><NavLink to={createUrl(printResults.linkType,pagination.current+1)} rel="next" data-link="pagination">{pagination.current+1}</NavLink></li>
                        }
                        {pagination.next && pagination.total > pagination.next &&
                        <li><NavLink to={createUrl(printResults.linkType,pagination.current+2)} data-link="pagination">{pagination.current+2}</NavLink></li>
                        }
                        <li>...</li>
                        {pagination.next && 
                            <li><NavLink to={createUrl(printResults.linkType,pagination.next)} rel="next" data-link="pagination-next">Próxima →</NavLink></li>
                        }
                    </ul>
                </nav>
                <div className="label">Mostrando {printResults.label} {((current * 60) - 59).toLocaleString('pt-BR', opcoes)} a {(current * 60).toLocaleString('pt-BR', opcoes)} de {(pagination.total * 60).toLocaleString('pt-BR', opcoes)} no total</div>
            </>
            }
        </div>
    )
}

export default Pagination