import Header from "../Header"
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchReports, removeReports } from "../../store/reports"
import Loading from "../Loading";
import * as helpers from "../../utils/helpers"
import Error from "../Error";

const Reports = () => {

    useEffect(() => {
        document.body.classList.add('reports');
        return () => {
            document.body.classList.remove('reports');
            helpers.removeMetaData()
        };
    }, []); 

    const reports = useSelector(state => state.reports ? state.reports : []);

    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const [rcnpj, setRcnpj] = useState("");
    const [rsolicitante, setRsolicitante] = useState("");
    const [remail, setRemail] = useState("");
    const [rmsg, setRmsg] = useState("");
    const navigate = useNavigate();

    useEffect(() => { 
        dispatch(fetchReports())
        .then(()=>setLoaded(true))
        .then(()=>window.scrollTo({
            top: 0,
            behavior: "smooth"
        }))
        return () => dispatch(removeReports());
    }, [dispatch])

    let content = helpers.metaData("reports");
    helpers.applyMetaData(content)
    let myDataLayer = { "content_group": "reports", "content_level": "top", "environment": process.env.NODE_ENV }
    helpers.applyDataLayer(myDataLayer)

    // avoid being used in production
    if (process.env.NODE_ENV !== "development") return <Error />;

    if (!loaded) {
        return <Loading />;
    } else if (loaded && reports) {

        const filterReports = e => {
            e.preventDefault()

            let btnClearFilter = document.querySelector(".botoesDeFiltro button:last-child")
            
            let botoes = document.querySelectorAll(".botoesDeFiltro button")
            Object.values(botoes).forEach(botao=>botao.classList.remove("primary"))
            e.target.classList.add("primary")
            btnClearFilter.classList.remove("d-none")

            let reportList = document.querySelectorAll("article ul li")
            Object.values(reportList).forEach(reportItem=>{
                reportItem.classList.remove("d-none")
                if (reportItem.querySelector("span").innerHTML !== e.target.getAttribute("data-action")) reportItem.classList.add("d-none")
            })


        }
        const clearFilter = e => {
            e.preventDefault()
            let botoes = document.querySelectorAll(".botoesDeFiltro button")
            Object.values(botoes).forEach(botao=>botao.classList.remove("primary"))
            e.target.classList.add("d-none")

            let reportList = document.querySelectorAll("article ul li")
            Object.values(reportList).forEach(reportItem=>reportItem.classList.remove("d-none") )
        }
        const colaDados = e => {
            e.preventDefault()

            navigator.clipboard.readText()
                .then(textoColado => {
                    // Divide a string com base na tabulação
                    var dadosSeparados = textoColado.split('\t');
                    if (dadosSeparados.length >= 4) {
                        setRcnpj(dadosSeparados[0])
                        setRsolicitante(dadosSeparados[1])
                        setRemail(dadosSeparados[2])
                        setRmsg(dadosSeparados[3])
                    } else {
                        alert("O conteúdo da área de transferência é incompatível com os campos do formulário.")
                    }
                })
                .catch(error => {
                    console.error('Erro ao acessar a área de transferência:', error);
                });
            
        }
        const clearForm = () => {
            setRcnpj("")
            setRsolicitante("")
            setRemail("")
            setRmsg("")
        }

        document.addEventListener('paste', function (e) {
            colaDados(e);
        });
        document.addEventListener('keydown', function(e) { if (e.key === ';') colaDados(e); });

        const submitReport = async e => {
            e.preventDefault();
            if (rcnpj && rsolicitante && remail) {
                const formData = new FormData();
                formData.append('report[cnpj_base]', rcnpj)
                formData.append('report[nome]', rsolicitante)
                formData.append('report[email]', remail)
                formData.append('report[mensagem]', rmsg)

                const response = await fetch(`/api/reports`, {
                    method: "POST",
                    body: formData
                  });
                  if (response.ok) {
                    const reportCreated = await response.json();
                    clearForm();
                    // console.log("reportCreated",reportCreated)
                    navigate(`/reports/${reportCreated.report.id}`)
                  }
                
                
            } else {
                console.warn("⚠️ Preencha todos os campos obrigatórios")
            }
        }

        return (
            <>
                <Header />
                <div className="container bordered">
                    <h1>Solicitações de remoção de dados</h1>
                    <h2>Banco de dados: {reports.details.hostdb}</h2>
                    <div className="columns-330px_1fr">
                        <aside>
                            <div className="box">
                                <div className="title">
                                    <h3>Nova solicitação</h3>
                                    <button onClick={colaDados}><span role="img" aria-label="thumb up" aria-labelledby="paste" >📝</span> Colar</button>
                                </div>
                                <form onSubmit={submitReport}>
                                    <label htmlFor="rcnpj">CNPJ base</label>
                                    <input type="number" name="rcnpj" value={rcnpj} autoFocus required onChange={e => setRcnpj(e.target.value)} />
                                    <label htmlFor="rsolicitante">Solicitante</label>
                                    <input type="text" name="rsolicitante" value={rsolicitante} required onChange={e => setRsolicitante(e.target.value)} />
                                    <label htmlFor="remail">E-mail do solicitante</label>
                                    <input type="email" name="remail" value={remail} required onChange={e => setRemail(e.target.value)} />
                                    <label htmlFor="rmsg">Mensagem</label>
                                    <input type="text" name="rmsg" value={rmsg} onChange={e => setRmsg(e.target.value)} />
                                    <button className="primary">Criar solicitação</button>
                                </form>
                            </div>
                        </aside>
                        <main>
                            <article>
                                <div className="botoesDeFiltro">
                                    <button onClick={filterReports} data-action="pending"><span role="img" aria-label="thumb up" aria-labelledby="pending" >⌛️</span> Pendente</button>
                                    <button onClick={filterReports} data-action="accepted"><span role="img" aria-label="thumb up" aria-labelledby="accepted" >👍🏻</span> Aceitas</button>
                                    <button onClick={filterReports} data-action="denied"><span role="img" aria-label="thumb down" aria-labelledby="denied" >👎🏻</span> Rejeitadas</button>
                                    <button onClick={clearFilter} className="d-none"><span role="img" aria-label="red x" aria-labelledby="clear" >❌</span> limpar filtros</button>
                                </div>
                                <br/>
                                <ul>
                                {reports && 
                                    Object.values(reports.reports).map(report => {
                                        return (
                                            <li key={report.id}>
                                                <NavLink to={helpers.createUrl("report",{"id": report.id})}>{report.cnpj_base}</NavLink> <span className={report.status === "accepted" ? "active" : "inactive"}>{report.status}</span><br/> ({report.nome})
                                            </li>
                                        )})
                                }
                                </ul>
                            </article>
                        </main>
                    </div>
                </div>
            </>
        )
    }
}
export default Reports;