import React from "react"
import * as helpers from "../../utils/helpers"
import Ads from "../Ads"

const ModalMap = ({cnpj,setViewMap}) => {

    
    const closeMapModal = e => {
        e.preventDefault()
        // window.dataLayer.push({"action": "closeModal"})
        setViewMap(false)
    }
    let address = helpers.titleize(cnpj.endereco_logradouro_tipo) + " " + helpers.titleize(cnpj.endereco_logradouro) 
    address += cnpj.endereco_numero ? ", " + helpers.titleize(cnpj.endereco_numero) : ""
    address += cnpj.endereco_complemento ? ", " + helpers.titleize(cnpj.endereco_complemento) : ""
    address += ". " + helpers.titleize(cnpj.endereco_bairro + ". ")
    address += cnpj.endereco_codigo_municipio ? cnpj.endereco_codigo_municipio.nome_ibge + "/" + cnpj.endereco_codigo_municipio.uf : ""
    address += ". CEP:  " + cnpj.endereco_cep.slice(0, -3) + "-" + cnpj.endereco_cep.slice(-3)

    let gmapskey_infocnpj = "AIzaSyAtkajV6l-jGx8JLQt6Rqk8ZXNPNaLpUd8";

    return (
        <div className="modal">
            <button className="primary" onClick={closeMapModal}>Fechar</button>
            <div className="content">
                <iframe src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(address)}&key=${gmapskey_infocnpj}`} title="Onde fica" allowFullScreen={true} width="100%" height="400" frameBorder="0"></iframe>
                <p><strong>Endereço:</strong><br/>
                {address}</p>
                <Ads placement={`modal-map`} />
            </div>
            <div className="background"></div>
        </div>
    )
}
export default ModalMap;