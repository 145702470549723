const RECEIVE_CNAE = 'cnaes/receiveCnae';
const RECEIVE_CNAES = 'cnaes/receiveCnaes';
const REMOVE_CNAES = 'cnaes/removeCnaes';

const receiveCnae = (cnae) => {
    return {
      type: RECEIVE_CNAE,
      payload: cnae
    };
  };

const receiveCnaes = (cnaes) => {
  return {
    type: RECEIVE_CNAES,
    cnaes
  };
};

export const removeCnaes = () => {
  return {
    type: REMOVE_CNAES
  }
}

export const fetchCnaes = (page = 1) => async dispatch => {
  let baseUrl = "/api/cnaes"
  page ? baseUrl += `?pagina=${page}` : baseUrl += ""

  const res = await fetch(baseUrl)
  
    if (res.ok) {
      const cnaes = await res.json();
      dispatch(receiveCnaes(cnaes));
    }
  }

  export const fetchCnae = (cnaeId, local = null, page = 1) => async dispatch => {
    try {
      let baseUrl = `/api/cnaes/${cnaeId}`
      let params = {}
      if (page) params.pagina = page;
      if (local) params.slug = local;
      // console.log("params",params)
      const queryString = Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
      baseUrl += "?" + queryString
      const res = await fetch(baseUrl); // Chamada de API direta sem CSRF
      if (res.ok) {
        const cnae = await res.json();
        dispatch(receiveCnae(cnae));
      } else {
        // Lidar com erros, se necessário
        console.error('Erro na chamada de API:', res.status, res.statusText);
      }
    } catch (error) {
      // Lidar com erros de rede, se necessário
      console.error('Erro de rede:', error);
    }
  }

const cnaesReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_CNAE:
      // console.log("action",action)
      return { ...state, [action.payload.cnae.codigo]: action.payload.cnae, local: action.payload.local, cidades: action.payload.cidades, estabelecimentos: action.payload.estabelecimentos, pagination: action.payload.pagination };
      // return { ...action.cnaes };
      
    case RECEIVE_CNAES:
      return { ...action.cnaes };
    case REMOVE_CNAES:
      return {};
    default:
      return state;
  }
};

export default cnaesReducer;