
import React, { useEffect, useState } from "react";
import Header from "../Header"
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { fetchCnaes, removeCnaes } from "../../store/cnaes"
import * as helpers from "../../utils/helpers";
import SearchForm from "../SearchForm";
import Breadcrumbs from "../Breadcrumbs";
import ConsultaCnpjImg from '../../assets/consulta-cnpj.webp';
import CnaeItem from "../CnaeItem";
import Pagination from "../Pagination";
import CardSkeleton from "../CardSkeleton";
import Ads from "../Ads"

const ConsultaCnae = () => {

    const [searchParams] = useSearchParams();
    const cnaes = useSelector(state => state.cnaes ? state.cnaes : []);
    const [loaded,setLoaded] = useState(false);
    const pagina = searchParams.get("pagina")

    const dispatch = useDispatch();

    useEffect(() => {
        document.body.classList.add('listing');
        return () => {
            document.body.classList.remove('listing');
            helpers.removeMetaData()
        };
    }, []); // O array vazio significa que este useEffect será executado apenas uma vez (semelhante a componentDidMount e componentWillUnmount)

    useEffect(() => { 
        dispatch(fetchCnaes(pagina))
            .then(()=>setLoaded(true))
            .then(()=>window.scrollTo({
                top: 0,
                behavior: "smooth"
            }))
        return () => {
            dispatch(removeCnaes())
            setLoaded(false)
        }
    }, [dispatch,pagina])

    let content = helpers.metaData("consulta-cnae",{"ogImage":ConsultaCnpjImg})
    // if (loaded) console.log("cnaes",cnaes)
    helpers.applyMetaData(content)

    return (
        <>
            <Header />
            <div>
                <Breadcrumbs path={content.breadcrumbs} loaded={loaded} />
                <section className="container bordered" id="intro">
                    <h1>Consulta CNAE</h1>
                </section>
            </div>
            <section className="container bordered">
                <div className="columns-330px_1fr">
                    <aside>
                        {/* <div>
                            <div className="box">
                                <div className="title">
                                    <h3>Encontre uma atividade</h3>
                                </div>
                                <p>Utilize o campo abaixo e busque por nome ou código de uma atividade para ver empresas na sua cidade.</p>
                                <SearchForm target="cnae" />
                            </div>
                        </div> */}
                        <Ads placement="cnae-listing" />
                    </aside>
                    <main className="list">
                        {!pagina &&
                        <article>
                            <p>Você já se deparou com a sigla <strong>CNAE</strong> e ficou se perguntando o que ela significa e como pode ser útil para você ou seu negócio? Nesta página, vamos explorar o significado do CNAE, suas aplicações e como você pode consultar e entender os códigos que representam diferentes atividades econômicas.</p>

                            <h2>O que é CNAE?</h2>
                            <p>CNAE é a sigla para Classificação Nacional de Atividades Econômicas. É um sistema utilizado para padronizar e classificar as atividades econômicas exercidas por empresas e organizações. Essa classificação facilita a organização e análise de dados estatísticos, além de ser fundamental para diversos processos administrativos e regulatórios.</p>

                            <h2>Como funciona</h2>
                            <p>Cada atividade econômica é representada por um código numérico único na classificação CNAE. Esses códigos são agrupados em categorias e subcategorias, permitindo uma identificação precisa das atividades desenvolvidas por empresas de diferentes setores.</p>

                            <h2>Exemplos de Atividades e Códigos CNAE</h2>
                            <ul>
                                <li>Serviços de Consultoria em Marketing Digital: CNAE 7319-0/03</li>
                                <li>Fabricação de Produtos Alimentícios: CNAE 10.11-1</li>
                                <li>Atividades de Design de Interiores: CNAE 7410-2/02</li>
                                <li>Comércio Varejista de Eletrônicos: CNAE 47.41-5</li>
                            </ul>

                            <h2>Consultando o CNAE</h2>
                            <p>Para consultar o código CNAE de uma atividade específica você pode usar o formulário desta página digitando o nome da atividade. Você pode também buscar pelo código da atividade.</p>

                            <p>Entender o CNAE é essencial para empresas, empreendedores e profissionais que buscam compreender melhor o mercado e cumprir obrigações legais. Com este guia, esperamos ter esclarecido suas dúvidas sobre o CNAE e sua importância para o mundo dos negócios. Para mais informações e consultas, não hesite em explorar a nossa ferramenta de busca.</p>

                            <h2>Para que serve esta busca de CNAE</h2>
                            <p>Você pode usar esta busca para encontrar empresas que atuam em atividades em qualquer cidade do país. Basca encontrar a cidade na página da atividade.</p>

                        </article>}
                        <h2>Lista das atividades</h2>
                        {!loaded && <CardSkeleton cards={30} /> }
                        {loaded && cnaes && Object.values(cnaes.cnaes).map(cnae => <CnaeItem key={cnae.codigo} cnae={cnae} /> ) }
                        {cnaes.pagination &&
                            <Pagination pagination={cnaes.pagination} type="cnaePage" />
                        }
                    </main>
                </div>

            </section>
        </>
    )
}

export default ConsultaCnae;