const RECEIVE_POSTS = 'blogPosts/receiveBlogPosts';
const REMOVE_POSTS = 'blogPosts/removeBlogPosts';

const receiveBlogPosts = (estabelecimentos) => {
  return {
    type: RECEIVE_POSTS,
    payload: estabelecimentos
  };
};

export const removeBlogPosts = () => {
  return {
    type: REMOVE_POSTS
  }
}

export const fetchBlogPosts = () => async dispatch => {
    let baseUrl = "https://blog.escolas.com.br/wp-json/wp/v2/posts"
    const res = await fetch(baseUrl)
  
    if (res.ok) {
      const blogPosts = await res.json();
      console.log(blogPosts)
      dispatch(receiveBlogPosts(blogPosts));
    }
  }
  

const blogPostsReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_POSTS:
      return { ...action.payload };
    case REMOVE_POSTS:
      return {};
    default:
      return state;
  }
};

export default blogPostsReducer;