import Header from "../Header"
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as helpers from "../../utils/helpers";
import Ads from "../Ads";
import { useDispatch, useSelector } from "react-redux";
import { homeCnpjs, removeCnpjs } from "../../store/cnpjs"
import { homeSocios, removeSocios } from "../../store/socios"
import CnpjItem from "../CnpjItem";
import SocioItem from "../SocioItem";
import SearchForm from "../SearchForm";
import ogImage from "../../assets/og-image.webp"
import ogImageSmaller from "../../assets/og-image-smaller.webp"
import CardSkeleton from "../CardSkeleton";

const Home = () => {

    useEffect(() => {
        document.body.classList.add('home');
        return () => {
          document.body.classList.remove('home');
          helpers.removeMetaData()
        };
      }, []);

      const [loadedcnpj, setLoadedcnpj] = useState(false);
      const [loadedsocio, setLoadedsocio] = useState(false);
  
      const estabelecimentos = useSelector(state => state.cnpjs ? state.cnpjs : []);
      const socios = useSelector(state => state.socios ? state.socios : []);
      
      const dispatch = useDispatch();

      useEffect(() => { 
        dispatch(homeCnpjs())
            .then(()=>setLoadedcnpj(true))
        dispatch(homeSocios())
            .then(()=>setLoadedsocio(true))
        return () => {
            dispatch(removeCnpjs())
            dispatch(removeSocios())
            setLoadedcnpj(false)
            setLoadedsocio(false)
        };
      }, [dispatch])

    const content = helpers.metaData("home",{"ogImage":ogImage})
    helpers.applyMetaData(content)
    let myDataLayer = {
        "content_group": "home", 
        "content_level": "top",
        "environment": process.env.NODE_ENV
    }

    helpers.applyDataLayer(myDataLayer)

    const isMobile = window.innerWidth <= 700;
    const featuredImg = isMobile ? ogImageSmaller : ogImage

    
    return (
        <>
            <Header />
            <section className="bordered" id="intro">
                <div className="title"><h1>CNPJ</h1> das empresas no Brasil</div>
                <h2>Encontre informações sobre empresas e sócios</h2>
                <SearchForm target="cnpj" />
            </section>
            <section className="container bordered">
                <div className="columns-2">
                    <div className="list">
                        <div className="d-flex">
                            <h2>Veja estas empresas</h2>
                            <p>Lista completa e <NavLink to={helpers.createUrl("consulta-cnpj")} data-link="home-listing">consulta CNPJ</NavLink> →</p>
                        </div>
                        {!loadedcnpj && <CardSkeleton cards={5} /> }
                        {estabelecimentos.estabelecimentos &&
                        Object.values(estabelecimentos.estabelecimentos).map((estabelecimento) => <CnpjItem cnpj={estabelecimento} key={estabelecimento.id} />)}
                        
                    </div>
                    <div className="list">
                        <div className="d-flex">
                            <h2>Veja estes sócios</h2>
                            <p>Lista completa e <NavLink to={helpers.createUrl("consulta-socio")} data-link="home-listing">consulta de sócios</NavLink> →</p>
                        </div>
                        {!loadedsocio && <CardSkeleton cards={5} /> }
                        {socios.socios && Object.values(socios.socios).map(socio=> <SocioItem socio={socio} key={socio.id} /> ) }
                        
                    </div>
                </div>
                
            </section>
            <Ads placement={`home-topo`} />
            <main className="container content bordered">
                <figure>
                    <div className="media">
                        <img src={featuredImg} title="Info CNPJ - Consulta de CNPJ e sócios" alt="Consulta CNPJ do site infocnpj.com" width={1200} height={630} />
                    </div>
                    <figcaption>O site Info CNPJ é gratuito e fácil de usar: consulte situação cadastral e muito mais de empresas brasileiras</figcaption>
                </figure>
                <div className="columns-2">
                    <div>
                        <p>Bem-vindo ao Info CNPJ, a sua fonte de informações abrangentes sobre empresas no Brasil. Nosso banco de dados é atualizado mensalmente e oferece acesso gratuito a dados cruciais para tomada de decisões informadas.</p>

                        <h2>O que é o CNPJ?</h2>
                        <p>O Cadastro Nacional da Pessoa Jurídica, ou <strong>CNPJ</strong>, é o registro fiscal utilizado no Brasil para identificar empresas e organizações. Cada CNPJ é único e contém informações essenciais sobre a entidade, incluindo dados sobre a <strong>situação cadastral</strong>, sócios, endereço, telefone e e-mail.</p>

                        <h2>De Onde Vêm os Dados?</h2>
                        <p>Nossos dados são provenientes do Ministério da Fazenda do Brasil, garantindo que você tenha acesso às informações mais atualizadas e confiáveis. Apenas organizamos e disponibilizamos os dados cedidos pelo governo para fornecer acesso público a essas informações essenciais.</p>

                    </div>
                    <div>

                        <h2>As Informações São Confiáveis?</h2>
                        <p>Sim, as informações apresentadas em nosso site são totalmente confiáveis, pois são fornecidas pelo Ministério da Fazenda e refletem o estado atual das empresas cadastradas junto à Receita Federal. Estamos comprometidos em fornecer dados precisos e atualizados.</p>

                        <h2>Como Solicitar a Remoção dos Dados</h2>
                        <p>Entendemos que, apesar de as informações no nosso site serem de interesse público, você pode desejar solicitar a remoção dos seus próprios dados ou da empresa que representa. Para solicitar a remoção, basta clicar <a href="#dataRemoval" data-link="header-removal" onClick={helpers.handleDataRemovalClick}>aqui</a> e seguir as instruções. Respeitamos o direito à privacidade e estamos aqui para atender às suas necessidades.</p>

                        <p>Info CNPJ é a sua fonte confiável de informações empresariais. Explore nosso banco de dados e descubra detalhes sobre empresas e seus sócios, incluindo faixa etária e associações comerciais. Tudo isso, gratuitamente!</p>
                    </div>
                </div>
            </main>
        </>
    )
}
export default Home;