import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCnae, removeCnaes } from "../../store/cnaes"
import Header from "../Header"
import Breadcrumbs from "../Breadcrumbs"
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import Ads from "../Ads"
import * as helpers from "../../utils/helpers";
import CardSkeleton from "../CardSkeleton";
import Skeleton from "react-loading-skeleton";
import Error from "../Error";
import CnpjItem from "../CnpjItem";
import Pagination from "../Pagination";

const CnaeLocal = () => {
    const [searchParams] = useSearchParams();
    const {id,local} = useParams();
    const codigoCnae = id.split("-")[0]
    const [loaded, setLoaded] = useState(false);
    const pagina = searchParams.get("pagina")
    useEffect(() => {
        document.body.classList.add("listing");
        return () => {
            document.body.classList.remove("listing");
            helpers.removeMetaData()
        };
    }, []);

    const cnaes = useSelector(state => state.cnaes ? state.cnaes : []);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchCnae(codigoCnae,local,pagina))
        .then(()=>setLoaded(true))
        .then(()=>window.scrollTo({
          top: 0,
          behavior: "smooth"
        }))
      return () => {
        dispatch(removeCnaes())
        setLoaded(false)
        };
      }, [dispatch,id,codigoCnae,local,pagina])


    const changeCityUrl = (newCity) => {
        const parsedUrl = new URL(window.location.href);
        const pathParts = parsedUrl.pathname.split('/');
        pathParts[pathParts.length - 1] = newCity;
        parsedUrl.pathname = pathParts.join('/');
        return parsedUrl.href;
    }
    
    const shuffle = (array) => {
        let currentIndex = array.length, temporaryValue, randomIndex;
        
        // Enquanto ainda houver elementos para embaralhar
        while (0 !== currentIndex) {
        
            // Seleciona um elemento aleatório restante
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
        
            // E troca com o elemento atual
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }
    
    let content
    
    // console.log("whaaaat",cnaes)
    
    let trintaCidadesAleatorias
    let cities = {}
    let cidade

    let cnae = Object.values(cnaes)[0]

    if (loaded) {
        if (!cnae) return <Error />;
        
        cidade = Object.values(cnaes)[1]
        content = helpers.metaData("cnae",cnaes);
        helpers.applyMetaData(content)
        // helpers.applyDataLayer(myDataLayer)
        const cidadesEmbaralhadas = shuffle(Object.values(cnaes.cidades));

        // Selecionar os primeiros 30 elementos
        trintaCidadesAleatorias = cidadesEmbaralhadas.slice(0, 30);

        trintaCidadesAleatorias.sort((cidadeA, cidadeB) => {
            const nomeCidadeA = cidadeA.nome_ibge.toUpperCase(); // Convertendo para maiúsculas para ordenação sem distinção entre maiúsculas e minúsculas
            const nomeCidadeB = cidadeB.nome_ibge.toUpperCase();
            if (nomeCidadeA < nomeCidadeB) {
              return -1;
            }
            if (nomeCidadeA > nomeCidadeB) {
              return 1;
            }
            return 0;
          });

        for (let key in cnaes.cidades) {
            let cidade = cnaes.cidades[key];
            cities[cidade.nome_ibge] = cidade.slug;
        }
    
    }

    const listAllCities = (e) => {
        e.preventDefault()
        e.target.remove()
        const resultsList = document.querySelector(".box ul");
        resultsList.innerHTML = ""
        for (let city in cities) {
            const liElement = document.createElement("li");
            const aElement = document.createElement("a");
            aElement.setAttribute("data-link","cnae-cidade")
            aElement.href = cities[city];
            aElement.textContent = city;
            liElement.appendChild(aElement);
            resultsList.appendChild(liElement);
        }
    }
    
    if (loaded && local && !cidade) return <Error />;


    return (
        <>
            <Header />
            <div>
                <Breadcrumbs path={content?.breadcrumbs} loaded={loaded} />
                <section className="container bordered" id="intro">
                    <h1>{ loaded ? content.h1 : <Skeleton /> }</h1>
                    <h2>CNAE {codigoCnae}</h2>
                    <p dangerouslySetInnerHTML={{ __html: content?.metaDescription }}></p>
                </section>
            </div>
            <section className="container bordered">
                <div className="columns-330px_1fr">
                    <aside>
                        <div>
                            <div className="box">
                                <div className="title">
                                    <h3>Veja empresas também nestas cidades</h3>
                                </div>
                                <ul>
                                    { !loaded &&
                                        <Skeleton count={15} style={{ marginBottom: ".5em"}} />
                                    }
                                    { loaded && cnaes.cidades && 
                                        trintaCidadesAleatorias.map(cidade=> { return <li className={local === cidade.slug ? "active" : undefined } key={cidade.slug}><NavLink to={changeCityUrl(cidade.slug)} data-link="cnae-cidade">{cidade.nome_ibge}</NavLink></li> })
                                    }
                                </ul>
                                { cnaes.local && 
                                    <button onClick={listAllCities}>↓ Veja todas as cidades em {cnaes.local.uf} ↓</button>
                                }
                            </div>
                            <Ads placement={`listing_cnae-sidebar`} />
                        </div>
                    </aside>
                    <main className="list">
                        <h2>Lista das empresas com situação cadastral ativa</h2>
                        { !loaded && <CardSkeleton cards={20} /> }
                        { cnaes.estabelecimentos &&
                        Object.values(cnaes.estabelecimentos).map((estabelecimento,index) => {
                          if (index !== 0 && index % 5 === 0) {
                            return (
                              <div key={estabelecimento.id}>
                                <Ads />
                                <CnpjItem cnpj={estabelecimento} />
                              </div>
                            )
                          } else  {
                            return <CnpjItem cnpj={estabelecimento} key={estabelecimento.id} />
                          }
                        })}
                        { loaded && !cnaes.estabelecimentos &&
                            <p>Nenhuma empresa ativa desta atividade encontrada em {cnaes.local.nome_ibge}.</p>
                        }
                        { cnaes.pagination &&
                          <Pagination pagination={cnaes.pagination} type="cnaes" />
                        }
                    </main>
                </div>
            </section>
        </>
    )
}

export default CnaeLocal;