import {createStore, combineReducers, applyMiddleware, compose} from "redux"
import thunk from "redux-thunk"
import socios from "./socios"
import cnpjs from "./cnpjs"
import blogPosts from "./blogPosts"
import reports from "./reports"
import cnaes from "./cnaes"

const rootReducer = combineReducers({
    socios,
    cnpjs,
    blogPosts,
    reports,
    cnaes
});


let enhancer;

if (process.env.NODE_ENV === 'production') {
    enhancer = applyMiddleware(thunk);
} else {
    const logger = require('redux-logger').default;
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    enhancer = composeEnhancers(applyMiddleware(thunk, logger));
}
const configureStore = (preloadedState) => {
    return createStore(rootReducer, preloadedState, enhancer);
};

export default configureStore;