import Header from "../Header"
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchReport, removeReports, updateReport } from "../../store/reports"
import * as helpers from "../../utils/helpers"
import Error from "../Error";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Reports = () => {
    
    const {id} = useParams();

    useEffect(() => {
        document.body.classList.add('reports');
        return () => {
        document.body.classList.remove('reports');
        helpers.removeMetaData()
        };
    }, []); 

    const reports = useSelector(state => state.reports ? state.reports : []);

    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    
    useEffect(() => {
        dispatch(fetchReport(id))
          .then(()=>setLoaded(true))
        return () => {
          dispatch(removeReports())
          setLoaded(false)
      };
    }, [dispatch,id])


    let report = Object.values(reports)[0]

    // avoid being used in production
    if (process.env.NODE_ENV !== "development") return <Error />;

    let content = helpers.metaData("reports");
    helpers.applyMetaData(content)
    let myDataLayer = { "content_group": "reports", "content_level": "top", "environment": process.env.NODE_ENV }
    helpers.applyDataLayer(myDataLayer)


    const handleUpdate = async e => {
        e.preventDefault();
        let editedReport = {
            id: report.id,
            status: e.target.getAttribute("data-action")
        }
        dispatch(updateReport(editedReport))
    }

    return (
        <>
            <Header />
            <article className="container bordered">
                <h3><NavLink to="/reports">← voltar</NavLink></h3>
                <br/>
                <h1>CNPJ base: {report?.cnpj_base || <Skeleton />}</h1>
                <h2>Banco de dados: {reports?.details?.hostdb || <Skeleton />}</h2>
                { !loaded && <Skeleton />}
                { report ? ( 
                <main className="columns-330px_1fr">
                    <div>
                        <div className="box">
                            <div className="title">
                                <h3>Detalhes da solicitação {report?.id || <Skeleton />} <span className={report?.status === "accepted" ? "active" : "inactive"}>{report?.status || <Skeleton />}</span></h3>
                            </div>
                            <h4>CNPJ base</h4>
                            <p>{report?.cnpj_base || <Skeleton />}</p>
                            <h4>Nome na solicitação</h4>
                            <p>{report?.nome || <Skeleton />}</p>
                            <h4>E-mail de quem solicitou</h4>
                            <p><a href={`mailto:${report?.email}`}>{report?.email || <Skeleton />}</a></p>
                            <h4>Mensagem</h4>
                            <p>{report?.mensagem || <Skeleton />}</p>
                            <h4>Data da solicitação</h4>
                            <p>{report?.created_at || <Skeleton />}</p>
                            {report?.status === "pending" &&
                                <>
                                    <div className="title">
                                        <h3>O que fazer com esta solicitação?</h3>
                                    </div>
                                    <div className="d-flex">
                                        <button data-action="denied" onClick={handleUpdate}><span role="img" aria-label="thumb down" aria-labelledby="notification" >👎🏻</span> Rejeitar</button>
                                        <button data-action="accepted" onClick={handleUpdate} className="primary"><span role="img" aria-label="thumb up" aria-labelledby="notification" >👍🏻</span> Aceitar</button>
                                    </div>
                                </>
                            }
                            {report.status !== "pending" &&
                                <>
                                    <h4>Solicitação alterada em</h4>
                                    <p>{report.updated_at}</p>
                                </>
                            }
                        </div>
                    </div>
                    <div>
                        {reports?.estabelecimentos &&
                            <>
                                <div className="box" id="estabelecimentos" data-section-name="Estabelecimentos">
                                    <div className="title">
                                        <h3>Estabelecimentos do CNPJ base {report.cnpj_base}</h3>
                                    </div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col">Nome</th>
                                                <th scope="col">Situação</th>
                                                <th scope="col">CNPJ</th>
                                                <th scope="col">Localização</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.values(reports.estabelecimentos).map(unidade=>{
                                                return (
                                                    <tr key={unidade.id}>
                                                        <th scope="row">
                                                            <NavLink to={helpers.createUrl("cnpj",unidade)} data-link="cnpj-estabelecimentos">{unidade.nome_fantasia ? helpers.titleize(unidade.nome_fantasia) : helpers.titleize(unidade.empresa.razao_social)}</NavLink>
                                                            <span className="matriz_filial">{unidade.identificador_matriz_filial === 1 ? "Matriz" : "Filial"}</span>
                                                        </th>
                                                        <td><span className={unidade.situacao_cadastral === "02" ? "active" : "inactive" }>{helpers.situacaoCadastral(unidade.situacao_cadastral)}</span> {unidade.situacao_cadastral_data !== "0" && `em ${helpers.formatarData(unidade.situacao_cadastral_data)}`}</td>
                                                        <td>{helpers.formatarCNPJ(unidade.cnpj_completo)}</td>
                                                        <td>{helpers.titleize(unidade.endereco_bairro)}<br/>
                                                        {unidade.endereco_codigo_municipio &&
                                                        unidade.endereco_codigo_municipio.nome_ibge + "/" + unidade.endereco_codigo_municipio.uf }</td>
                                                    </tr>
                                                )}
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        }
                    </div>
                </main>
                ) : ( <Skeleton /> )
                }
            </article>
        </>
    )
}
export default Reports;