const RECEIVE_REPORT = 'reports/receiveReport';
const RECEIVE_REPORTS = 'reports/receiveReports';
const REMOVE_REPORTS = 'reports/removeReports';

const receiveReport = (reports) => {
    return {
      type: RECEIVE_REPORT,
      reports
    };
  };

const receiveReports = (reports) => {
  return {
    type: RECEIVE_REPORTS,
    reports
  };
};

export const removeReports = () => {
  return {
    type: REMOVE_REPORTS
  }
}

export const fetchReports = () => async dispatch => {
  let baseUrl = "/api/reports"
  const res = await fetch(baseUrl)
  
    if (res.ok) {
      const reports = await res.json();
      dispatch(receiveReports(reports));
    }
  }

  export const updateReport = (report) => async (dispatch) => {
    let baseUrl = `/api/reports/${report.id}`
    const response = await fetch(baseUrl, {
      method: "PUT",
      body: JSON.stringify({
        report
      }),
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
      }})
      const data = await response.json();
      dispatch(receiveReport(data));
      return response;
  }
  
  export const fetchReport = (reportId) => async dispatch => {
    try {
      const res = await fetch(`/api/reports/${reportId}`); // Chamada de API direta sem CSRF
      if (res.ok) {
        const report = await res.json();
        dispatch(receiveReport(report));
      } else {
        // Lidar com erros, se necessário
        console.error('Erro na chamada de API:', res.status, res.statusText);
      }
    } catch (error) {
      // Lidar com erros de rede, se necessário
      console.error('Erro de rede:', error);
    }
  }

  export const createReport = (report) => async (dispatch) => {
    const response = await fetch(`/api/reports`, {
      method: "POST",
      body: JSON.stringify({
        report
      }),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    });
    const data = await response.json();
    dispatch(receiveReport(data));
    return response;
  }
  

const reportsReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_REPORT:
    //   return { ...state, [action.payload.report.id]: action.payload };
      return { ...action.reports };
    case RECEIVE_REPORTS:
      return { ...action.reports };
    case REMOVE_REPORTS:
      return {};
    default:
      return state;
  }
};

export default reportsReducer;